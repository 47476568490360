/*!
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

$max-width-mobile: 767px;
$min-width-tablet: 768px;
$max-width-tablet: 1199px;
$min-width-desktop: 1200px;
