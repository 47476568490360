/*!/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

$white: #dedede;
$grey: #d7d4e5;
$light-purple: #2d2a46;
$purple: #1a182a;
$deep-purple: #151322;
$black-purple: #0e0d17;
$green: #02dac9;
$red: #e96464;
