/*!/*
 * This unpublished material is proprietary to Vaticle.
 * All rights reserved. The methods and
 * techniques described herein are considered trade secrets
 * and/or confidential. Reproduction or distribution, in whole
 * or in part, is forbidden except by express written permission
 * of Vaticle.
 */

$light-grey: #958fa8;
$grey: #63607c;
$mid-deep-grey: #4e4b63;
$deep-grey: #383649;
$purple: #232135;
$yellow: #ffe49e;
$pink: #ff87dc;
$blue: #78a0ff;
